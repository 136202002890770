<template>
  <!-- 账号修改页面 -->
  <moe-page title="账号修改">
    <moe-form ref="accountForm" :model="account" :rules="accountRules">
      <el-form-item label="账号" prop="account">
        <el-input
          v-model.trim="account.account"
          placeholder="请输入账号"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model.trim="account.name"
          placeholder="请输入姓名"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input
          v-model.trim="account.pwd"
          type="password"
          placeholder="**********"
          maxlength="50"
          clearable
          show-password
        />
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select
          v-model="role"
          placeholder="请选择角色"
          filterable
          clearable
          value-key="id"
          :disabled="isSameAccount"
          @change="(e) => (account.roleId = e.id)"
        >
          <el-option
            :label="item.name"
            :value="item"
            v-for="item of roleList"
            :key="item.id"
          />
        </el-select>
        <div style="margin-top: 10px">
          <el-input
            :value="role.info"
            type="textarea"
            placeholder="角色描述"
            :rows="5"
            readonly
            :disabled="isSameAccount"
          />
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="account.state" :disabled="isSameAccount">
          <el-radio :label="0">启用</el-radio>
          <el-radio :label="-1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="accountLoad"
          @click="accountInfoSubmit"
        >
          {{ accountLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AccountAmend',
  data() {
    //账号信息
    const account = {
      account: '', //账号
      name: '', //姓名
      pwd: '', //密码
      roleId: '', //角色
      state: 0, //状态
    };

    //账号数据校验
    const verify = this.$moe_verify.verifyForm;
    const accountRules = {
      account: verify.isAccount([
        '请输入账号',
        '账号格式为 字母或数字组成，长度为1~30',
      ]),
      name: verify.isExtent(['请输入姓名', '长度不能超过 1 ~ 10'], '1~10'),
      pwd: verify.isPassword([
        '密码格式为 字母或数字组成，长度为6 ~ 16',
      ],false),
      roleId: verify.isEmpty('请选择角色'),
      state: verify.isEmpty('请选择状态'),
    };

    return {
      account, //账号信息
      accountRules, //账号数据校验
      role: {}, //角色信息
      roleList: [], //角色列表
      accountLoad: false, //提交状态
    };
  },
  created() {
    //获取所有角色信息
    this.getAllRoleList();
  },
  computed: {
    // 获取用户信息
    ...mapState('user', ['user']),

    // 当前账号是否相同
    isSameAccount() {
      return this.user.id === this.account.id;
    },
  },
  methods: {
    /**
     * 获取账号详情
     **/
    getAccountDetail() {
      // 获取账号ID
      const id = this.$route.query.id;

      // 调用所有角色API
      this.$moe_api.ACCOUNT_API.accountDetail({ id }).then((data) => {
        if (data.code == 200) {
          let a = data.result;

          this.account = {
            id: a.id, //账号ID
            account: a.account, //账号
            name: a.name, //姓名
            // pwd: a.pwd, //密码
            roleId: a.roleId, //角色
            state: a.state, //状态
          };
          this.role = this.roleList.find((k) => k.id === a.roleId) || {};
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 获取所有角色信息
     **/
    getAllRoleList() {
      // 调用所有角色API
      this.$moe_api.ROLE_API.roleListAll({
        pageNum: 1,
        pageSize: 10,
        name: '',
      }).then((data) => {
        if (data.code == 200) {
          this.roleList = data.result;

          //获取账号详情
          this.getAccountDetail();
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 提交账号信息
     **/
    accountInfoSubmit() {
      // 表单校验
      this.$refs['accountForm'].validate(() => {
        this.accountLoad = true;
        // console.log(this.account)
        // 调用账号修改API
        this.$moe_api.ACCOUNT_API.accountAmend(this.account)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('修改成功');

              // 更新当前用户信息
              this.isSameAccount && this.$store.dispatch('user/updateUserInfo');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.accountLoad = false;
          });
      });
    },
  },
};
</script>
